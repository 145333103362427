import React, { useState } from "react";
import { bool, string } from "prop-types";
import DOMPurify from 'dompurify';
import { useIntl } from "react-intl";
import {
  Button as StorybookButton,
  TooltipModal as StorybookTooltipModal,
} from "@sunbeltrentals/sbr-global-components";
import { ENV_CONFIG } from "../../../../constants/envConfig";
import InfoIcon from "../../../../resources/images/info.svg";
import CloseIcon from "../../../../resources/images/close.svg";
import './requiredAccessoriesAddonsInfoModal.scss';
import { checkoutDatalocator } from "../../../checkoutv2/checkoutAndOrderSummary/dataLocators";

const RequiredAccessoriesAddonsInfoModal = (props) => {
  const { isModalView = false, title = "" } = props || {};
  const [showModal, setShowModal] = useState(false);
  let { requireditemslabel = "", requireditemsmodaltext = "" } = ENV_CONFIG.MINICART_GENERIC_CONFIGS;

  const intl = useIntl();

  const handleOnModalToggle = () => {
    setShowModal(!showModal);
  };

  const getTooltipContent = () => {
    return (
        <div
          className="required-rentals-info-desc"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              requireditemsmodaltext ||
                intl.formatMessage({ id: "pdp:reqired-rentals-desc" }),
            ),
          }}
        />
    )
  }

  const getInfoIcon = () => {
    return (
        <InfoIcon
            aria-label={title}
            tabIndex={0}
            role="tooltip"
            onClick={handleOnModalToggle}
            onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                handleOnModalToggle();
                }
            }}
        />
    );
  }

  const getToolTipIcon = () => (
    <StorybookTooltipModal
      isOpen={showModal}
      handleOnModalToggle={handleOnModalToggle}
      tooltipInfoButton={getInfoIcon()}
      title={
        requireditemslabel ||
        intl.formatMessage({ id: "pdp:reqired-rentals-title" })
      }
      content={getTooltipContent()}
      isShowButton={true}
      closeIconTooltip={<CloseIcon aria-hidden="true" tabIndex={"-1"} />}
    />
  );

  return isModalView ? (
    <StorybookButton
      dataTestid={checkoutDatalocator.checkoutForcedItemRequired}
      className="toolTipIcon rentals_required_btn"
      onClick={handleOnModalToggle}
    >
      {intl.formatMessage({ id: "addons:required-btn-label" })}
      {getToolTipIcon()}
    </StorybookButton>
  ) : (
    getToolTipIcon()
  );
};

RequiredAccessoriesAddonsInfoModal.propTypes = {
  isModalView: bool,
  title: string,
};

export default React.memo(RequiredAccessoriesAddonsInfoModal);
